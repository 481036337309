require("./_config")

global.jQuery = require("jquery")
global.$ = jQuery

const $ = require("../../node_modules/jquery/dist/jquery.min")
const ElementQueries = require("../../node_modules/css-element-queries/src/ElementQueries")
const mobileMenu = require("./_mobilemenu")
const accordion = require("./_accordion")
const popup = require("./_popup")

$(() => {
  mobileMenu.init()
  accordion.init()
  popup.init()
})

ElementQueries.listen()
