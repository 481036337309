require('../../node_modules/jqueryui/jquery-ui')

module.exports = {
  init: () => {
    $(function () {
      $('.accordion')
        .accordion({
          collapsible: true,
          heightStyle: 'content',
          active: false
        })
        .show()
    })
  }
}
